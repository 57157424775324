<template>
    <section class="container mt-0 pt-1 pb-10">
      <div class="row text-center mb-5">
          <div class="col-md-8 mx-auto">
            <h3>Nos partenaires</h3>
          </div>
        </div>
      <div class="row text-center">
        <div class="col-md-8 mx-auto">
          <div
            class="d-flex flex-column flex-md-row justify-content-center align-items-center"
          >
           <a href="https://www.mysweetimmo.com/2021/09/09/pros-de-limmo-avec-melo-vous-balayez-plus-de-900-plateformes-dannonces-immobilieres" target="_blank" rel="noopener">
              <b-img
                class="w-15 mb-5 mb-md-0"
                alt="rent"
                :src="require('@/assets/logo/mysweetimmo.svg')"
              ></b-img>
            </a>
            <a href="https://www.rent.immo/" target="_blank" rel="noopener">
              <b-img
                class="w-5 ml-md-5 mb-5 mb-md-0"
                alt="rent"
                :src="require('@/assets/logo/rent.svg')"
              ></b-img>
            </a>
            <a
              href="https://immo2.pro/actualite-immobilier/actualite-du-secteur/melo-annonce-la-mise-a-disposition-de-ses-donnees-immobilieres-sous-forme-dapi/"
              target="_blank"
              rel="noopener"
            >
              <b-img
                class="w-15 ml-md-5 mb-5 mb-md-0"
                alt="immo2pro"
                :src="require('@/assets/logo/immo2pro.svg')"
              ></b-img>
            </a>                      
            <a
              href="https://www.immomatin.com/portails/meta-moteurs/melo-le-nouvel-agregateur-d-annonces-immobilieres-aux-800-sources.html"
              target="_blank"
              rel="noopener"
            >
              <b-img
                class="w-5 ml-md-5"
                alt="immomatin"
                :src="require('@/assets/logo/immomatin.svg')"
              ></b-img>
            </a>
          </div>
        </div>
        <div class="col-md-8 mx-auto">
          <div
            class="d-flex flex-column flex-md-row justify-content-center align-items-center"
          >
          <a
              href="https://zapier.com/apps/melo/integrations"
              target="_blank"
              rel="noopener"
            >
              <b-img
                class="w-5 ml-md-5 mb-md-0"
                alt="zapier"
                :src="require('@/assets/logo/zapier.svg')"
              ></b-img>
            </a>
           
            <a
              href="https://immo2.pro/annuaire/prestataire/startups/melo/"
              target="_blank"
              rel="noopener"
            >
              <b-img
                class="w-5 ml-md-5 mb-5 mb-md-0"
                alt="lareserve"
                :src="require('@/assets/logo/lareserve.svg')"
              ></b-img>
            </a>
            <a
              href="https://www.make.com/en/register?pc=virus"
              target="_blank"
              rel="noopener"
            >
              <b-img
                class="w-5 ml-md-5 mb-md-0"
                alt="make"
                :src="require('@/assets/logo/make.svg')"
              ></b-img>
            </a>
          </div>
        </div>
       
      </div>
    </section>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
 h3 {
      font-weight: bold;
      @include media-breakpoint-up(md) {
        font-size: 3rem;
      }
    }
</style>